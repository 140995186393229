import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import { unregister } from './registerServiceWorker'
import './scss/app.css'
import 'font-awesome/css/font-awesome.css'
import { BrowserRouter } from 'react-router-dom'

ReactDOM.render((
  <BrowserRouter>
    <App />
  </BrowserRouter>
)
  , document.getElementById('root'))

unregister()
