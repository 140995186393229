import React from "react"
import {ReactComponent as TeamImage} from "../../assets/about/team.svg"

class About extends React.Component {
  render () {
    return <div className="about">
      <div className="container">
        <div className="about-content space-30-top">
          <div className="row">
			<div className="col-md-6 text-left">
				<TeamImage className="img-responsive"></TeamImage>
			</div>
            <div className="col-md-6">
              <div>
                <p className="text-heading-3 space-16-bottom">About Ajaro</p>
                <p className="text-heading-5 space-24-bottom">Ajaro Indonesia, berdiri sejak tahun 2017. Kami merupakan sebuah perusahaan yang bergerak dibidang Teknologi dan Informasi serta fokus terhadap pengembangan sumberdaya manusia terutama generasi muda dalam bidang Teknologi. Ajaro tidak didirikan oleh satu orang saja, melainkan dibentuk dan didirikan berdasarkan mimpi, semangat dan harapan dari sekelompok orang yang ingin berbagi dan memberikan dampak positifnya terhadap masyarakat dan tentunya Indonesia tercinta.</p>
                <p className="text-heading-5">Sesuai dengan salah satu visi kami, menjadi media perantara antara dunia pendidikan dan dunia kerja, mengingat semakin hari teknologi berkembang dengan pesat dan dunia pendidikan juga harus mengikuti perkembangan tersebut. Kami yakin dengan adanya perantara ini dunia pendidikan bisa menyesuaikan kebutuhan industri saat ini dan mempertemukan industri dengan tenaga kerja yang sesuai kriteria mengingat semakin meningkatnya kebutuhan industri akan tenaga kerja.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default About
