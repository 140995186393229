import React from 'react'

import {ReactComponent as IconTwitter} from '../assets/icons/icon-twitter.svg'
import {ReactComponent as IconFacebook} from '../assets/icons/icon-facebook.svg'
import {ReactComponent as IconInstagram} from '../assets/icons/icon-instagram.svg'

class Footer extends React.Component {
  render () {
    return <div className='footer'>
      <div className='container'>
        <div className="row">
			<div className="col-md-6">
				<div className="flex">
					<p>Follow us</p>
					<div>
						<a
							href='https://facebook.com/ajaro.id/'
							target="_blank"
							rel="noopener noreferrer"
							className="space-8-left"
						>
							<IconFacebook></IconFacebook>
						</a>
						<a
							href='https://www.instagram.com/id.ajaro/'
							target="_blank"
							rel="noopener noreferrer"
							className="space-24-left"
						>
							<IconInstagram></IconInstagram>
						</a>
						<a
							href='https://twitter.com/ajaro_id'
							target="_blank"
							rel="noopener noreferrer"
							className="space-24-left"
						>
							<IconTwitter></IconTwitter>
						</a>
					</div>
				</div>
			</div>
			<div className="col-md-6 hidden-xs">
				<p className="text-right">
					<span className="space-16-left">
						<a href="https://goo.gl/maps/dy3A3jTMbwM62x6D8" target="_blank" className="text-grey-3" rel="noopener noreferrer">Location</a>
					</span>
					<span className="space-16-left">Join Our Team</span>
					<span className="space-16-left">FAQ</span>
				</p>
			</div>
			<div className="col-xs-12 space-30-top">
            	<p className='text-center text-grey-2 text-heading-6'>Copyright @ 2017 - {new Date().getFullYear()} by PT. Ajaro Solusi Indonesia</p>
			</div>
        </div>
      </div>
    </div>
  }
}

export default Footer
