import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
import Content from './Content'

const App = () => (
  <div>
    <Navbar />
    <Content />
    <Footer />
  </div>
)

export default App
