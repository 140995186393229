import React from "react"
import {ReactComponent as CodingClassImage} from "../Services/assets/coding-class.svg"
import {ReactComponent as TalentPoolImage} from "../Services/assets/talent-pool.svg"
import {ReactComponent as SoftwareDevelopmentImage} from "../Services/assets/software-development.svg"
import { Link } from 'react-router-dom'

class Services extends React.Component {
  render () {
    return <div className="about">
      <div className="container">
          <div className="row">
			<div className="col-sm-8 col-sm-offset-2 text-left">
				<p className="text-heading-3 text-center space-48-bottom">What We Do</p>
                <div className="row space-48-bottom">
                    <div className="col-sm-6">
                        <CodingClassImage className="img-responsive space-24-bottom"></CodingClassImage>
                    </div>
                    <div className="col-sm-6">
                        <p className="text-heading-4 text-600">Ajaro Coding Class</p>
                        <p className="text-grey-3 text-heading-5">Belajar programming secara offline dengan mentor profesional dibidangnya. Kelas kami mulai dari basic, intermediate hingga advance.</p>
                        <Link to="/coding-class">
                            <button className="btn btn-primary">See More</button>
                        </Link>
                    </div>
                </div>
                <div className="row space-48-bottom">
                    <div className="col-sm-6">
                        <p className="text-heading-4 text-600">Talent Pool</p>
                        <p className="text-grey-3 text-heading-5">Kami memberikan pelatihan kepada talent dibidang Software Development sampai siap bekerja sesuai dengan kebutuhan Industri saat ini.</p>
                    </div>
                    <div className="col-sm-6">
                        <TalentPoolImage className="img-responsive space-24-bottom"></TalentPoolImage>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <SoftwareDevelopmentImage className="img-responsive space-24-bottom"></SoftwareDevelopmentImage>
                    </div>
                    <div className="col-sm-6">
                        <p className="text-heading-4 text-600">Software Development</p>
                        <p className="text-grey-3 text-heading-5">Kami juga membantu para pelaku bisinis untuk mengembangkan Software sesuai dengan kebutuhan bisnis mereka.</p>
                    </div>
                </div>
			</div>
          </div>
      </div>
    </div>
  }
}

export default Services
