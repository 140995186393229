import React from 'react'
import { Link } from 'react-router-dom'

class Navbar extends React.Component {
  constructor (props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick () {
    if (document.querySelector('#myNavbar').classList.contains('in')) {
      document.querySelector('.navbar-toggle').click()
    }
    // window.location.reload();
    window.scrollTo(0, 0)
  }
  render () {
    return <div id="navbar">
      <nav className="navbar navbar-inverse">
        <div className="container">
          <Link className="navbar-brand visible-xs" to="/" onClick={this.handleClick}>
            <img src="./assets/ajaro-logo.png" alt="" className="img-responsive"/>
          </Link>
          <div className="navbar-header">
            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
          </div>
          <div className="collapse navbar-collapse" id="myNavbar">
			<Link className="navbar-brand hidden-md" to="/" onClick={this.handleClick}>
				<img src="./assets/ajaro-logo.png" alt="" className="img-responsive"/>
			</Link>
            <ul className="nav navbar-nav text-right">
              <li className="text-heading-6" onClick={this.handleClick}><Link to="/about">ABOUT</Link></li>
              <li className="text-heading-6" onClick={this.handleClick}><Link to="/services">SERVICES</Link></li>
              {/* <li onClick={this.handleClick}><Link to='/services'>SERVICES</Link></li> */}
              {/* <li onClick={this.handleClick}><Link to='/internship'>Internship</Link></li> */}
              {/* <li onClick={this.handleClick}><Link to='/mentors'>Mentors</Link></li> */}
              {/* <li onClick={this.handleClick}><Link to='/testimonial'>Testimonial</Link></li> */}
              {/* <li onClick={this.handleClick}><Link to='/mgmp'>Event</Link></li> */}
              <li className="text-heading-6" onClick={this.handleClick}><a href="https://blog.ajaro.id/" target="_blank" rel="noopener noreferrer">BLOG</a></li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  }
}

export default Navbar
