import React from "react"
import { Switch, Route } from "react-router-dom"
import Home from "./Home/Home"
import About from "./About/About"
import Services from "./Services/Services"
import CodingClass from "./CodingClass/CodingClass"

const Content = () => (
  <content>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/about" component={About} />
      <Route path="/services" component={Services} />
      <Route path="/coding-class" component={CodingClass} />
    </Switch>
  </content>
)

export default Content