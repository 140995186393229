import React from "react"
import { ReactComponent as CodingClassImage } from "../Services/assets/coding-class.svg"
import { ReactComponent as FrontendImg } from "../CodingClass/assets/frontend.svg"
import { ReactComponent as BackendImg } from "../CodingClass/assets/backend.svg"
import { ReactComponent as DatabaseImg } from "../CodingClass/assets/database.svg"
import { ReactComponent as MobileImg } from "../CodingClass/assets/mobile.svg"

class CodingClass extends React.Component {
    render() {
        return <div className="coding-class">
            <div className="container-grey-soft space-24-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 col-sm-offset-2 text-left">
                            <div className="row p-48-bottom p-48-top">
                                <div className="col-sm-6">
                                    <CodingClassImage className="img-responsive"></CodingClassImage>
                                </div>
                                <div className="col-sm-6">
                                    <p className="text-heading-2 text-600">Ajaro Coding Class</p>
                                    <p className="text-grey-3 text-heading-5">Belajar programming secara offline dengan mentor profesional dibidangnya. Kelas kami mulai dari basic, intermediate hingga advance.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-8 col-sm-offset-2 text-left">
                        <div className="row space-48-bottom">
                            <div className="col-sm-12">
                                <p className="text-center text-heading-3">What You Can <span className="text-primary">Learn</span> In</p>
                                <p className="text-center text-heading-3">Ajaro Coding Class</p>
                            </div>
                        </div>
                        <div className="row space-48-bottom">
                            <div className="col-sm-6">
                                <FrontendImg className="img-responsive space-24-bottom"></FrontendImg>
                            </div>
                            <div className="col-sm-6">
                                <p className="text-heading-4 text-600">Frontend Development</p>
                                <p className="text-grey-3 text-heading-5">Merealisasikan sebuah design dan mengintegrasikan data dari sebuah Restful API Service menjadi sebuah website interaktif yang tampil pada sebuah browser.</p>
                            </div>
                        </div>
                        <div className="row space-48-bottom">
                            <div className="col-sm-6">
                                <p className="text-heading-4 text-600">Backend Development</p>
                                <p className="text-grey-3 text-heading-5">Merupakan server side programming, yaitu segala hal yang berhubungan dengan server dan database, membuat sebuah Restful API service dan segala hal yang berhungan dengan data.</p>
                            </div>
                            <div className="col-sm-6">
                                <BackendImg className="img-responsive space-24-bottom"></BackendImg>
                            </div>
                        </div>
                        <div className="row space-48-bottom">
                            <div className="col-sm-6">
                                <DatabaseImg className="img-responsive space-24-bottom"></DatabaseImg>
                            </div>
                            <div className="col-sm-6">
                                <p className="text-heading-4 text-600">Database</p>
                                <p className="text-grey-3 text-heading-5">Database merupakan tempat penyimpanan data, yang kita pelajari disini adalah bagaimana merancang database sesuai dengan kebutuhan sebuah aplikasi.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <MobileImg className="img-responsive space-24-bottom"></MobileImg>
                            </div>
                            <div className="col-sm-6">
                                <p className="text-heading-4 text-600">Mobile Development</p>
                                <p className="text-grey-3 text-heading-5">Mengembangkan sebuah aplikasi berbasis mobile pada sebuah smarthphone.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default CodingClass