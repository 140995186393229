import React from "react"
import {ReactComponent as DevFocusImage} from "../../assets/home/dev-focus.svg"
import {ReactComponent as LoadingImg} from '../../assets/loading.svg'
import { Link } from "react-router-dom"

class Home extends React.Component {
  constructor (props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick () {
    window.location.reload()
    window.scrollTo(0, 0)
  }
  render () {
    return <div className="home">
      	<div className="">
			<div className="container">
				<div className="row p-48-top p-48-bottom">
					<div className="col-sm-12">
						<div className="row">
						<div className="col-sm-6">
								<p className="text-heading-1">Learn</p>
								<p className="text-heading-1">Programming</p>
								<p className="text-heading-1">from
									<span className="text-primary"> Scratch</span>
								</p>
								<Link to="/coding-class">
									<button className="btn btn-primary btn-lg space-16-top space-16-bottom">See More</button>
								</Link>
						</div>
						<div className="col-sm-6">
							<DevFocusImage
								className="img-responsive pull-right space-16-top"
								>
							</DevFocusImage>
						</div>
					</div>
					</div>
				</div>
			</div>
		</div>
      <LoadingImg />
		</div>
	}
}

export default Home
